export const genericUtils = {

    /**
     * Get a random time interval that's between a median value.
     * @default 15 seconds
     * @param time - number in seconds
     * @return number - number in milliseconds
     */
    randomTimeInterval: (time: number = 15) => {
        const randomValue = Math.floor(Math.random() * 3); // between 0 - 2 inclusive
        const randomSign = Math.random() < 0.5 ? -1 : 1;
        return (time + randomValue * randomSign) * 1000;
    }
};