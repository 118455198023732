import React, { FC, useEffect, useState } from 'react';
import { Asset } from 'hoh-ksa-metamart-sdk';
import './App.css'
import { useAssets } from './hooks/swr';

function App() {

  const [asset, setAsset] = useState<Asset>();
  const { data, isValidating, isLoading } = useAssets();
  const [isArabic, setIsArabic] = useState<boolean | undefined>();

  // Reload every half an hour
  useEffect(() => {
    const reloadInterval = 30 * 60 * 1000;
    const reloadPage = () => {
      window.location.reload();
    };
    const intervalId = setInterval(reloadPage, reloadInterval);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    if (data && !isValidating && !isLoading) {
      setAsset(data[0]);
      setIsArabic(data[0].locale === 'ar');
    }
  }, [asset, isValidating, isLoading, data])

  const Content: FC<Asset> = props => {
    const { imageUrl, type, description, qrCode, subTitle, title } = props;
    if (["NFT", "PRODUCT"].includes(type)) {
      return (
        <div className='grid grid-cols-1 grid-rows-2 h-screen text-white bg-[black]'>
          <img className='object-cover h-full w-full col-span-1 row-span-1' src={imageUrl} alt='content' />
          <div className={`h-full flex flex-col p-10 row-span-2`}>
            <h1 className={`text-7xl uppercase ${isArabic ? 'font-GE_SS_Two' : 'font-PPMonumentExtended'}`}>{title}</h1>
            <div className='h-full flex flex-col justify-end'>
              <h2 className={`text-neutral-400 text-4xl uppercase mb-10 ${isArabic ? 'font-GraphikArabic' : 'font-InterSemiBold'}`} >{subTitle}</h2>
              <div className={`h-80 grid grid-flow-col`}>
                {qrCode && <img className='object-contain pe-4 me-4 h-80' src={qrCode} alt='qr-code' />}
                <p className={`text-3xl max-h-80 overflow-hidden align-text-top description_nft ${isArabic ? 'font-GraphikArabic' : 'font-InterRegular'}`}>{description}</p>
              </div>
            </div>
          </div>
        </div>
      );
    }
    if (type === "STATIC_IMAGE") {
      if (!description) {
        return <img className='object-cover h-screen w-full' src={imageUrl} alt='content' />;
      } else {
        return (
          <div className='flex flex-col h-screen text-white bg-[black]'>
            <img className='object-cover h-[85%]' src={imageUrl} alt='content' />
            <div className='flex p-10'>
              <img className='w-[40%] me-4 object-contain' src='./HOH_Mark.png' alt='hoh-logo' />
              <p className={`ms-4 text-2xl max-h-56 description_static_img ${isArabic ? 'font-GraphikArabic' : 'font-InterRegular'}`}>{description}</p>
            </div>
          </div>
        );
      }
    }
    console.error(`Ambiguous asset type: ${type}`)
    return null;
  }

  if (!asset) {
    return null;
  }

  return <div dir={isArabic ? "rtl" : "ltr"}><Content {...asset} /></div>;
}

export default App
