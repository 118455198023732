import { Asset } from "hoh-ksa-metamart-sdk";
import useSwr, { SWRResponse } from "swr";
import {genericUtils} from "../utils/genericUtils";

const fetcher = (): Promise<Asset[]> =>
  fetch(`${process.env.REACT_APP_CMS_API_URL}/v1/assets`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${process.env.REACT_APP_STRAPI_AUTH_TOKEN}`,
    },
  })
    .then((response) => response.json())
    .then((response) => response.data);

export const useAssets = (): SWRResponse<Asset[]> =>
  useSwr(
      "/v1/assets",
      fetcher,
      {
          refreshInterval: genericUtils.randomTimeInterval(parseInt(process.env.REACT_APP_REFRESH_INTERVAL ?? "15"))
      }
  );
